import React, { useState, useEffect } from "react";
import "../headerTop/HeaderTop.css";

const HeaderTop = () => {
  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div className="header__top">
      <div className={`active1 ${show && "hidden"}`}>
        <div className="container pt-1">
          <div className="row d-flex justify-content-between">
            <div className="col-md-8 ">
              <div className="ht-email">
                <span className="mr-3">
                  <i className="mr-1 fa-solid fa-phone"></i> +66840673605
                </span>
                <span className="mr-3">
                  <i className="mr-1 fa-solid fa-envelope"></i>
                  info@zaimah.co.th
                </span>
                {/* <span className="mr-3">
                  <i className="mr-1 fa-solid fa-location-dot"></i> Level 11,
                  540 Mercury Tower, Ploenchit Road, Lumpini, Pathumwan, Bangkok
                  10330, Thailand
                </span> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="ht-social">
                <a
                  href="https://www.facebook.com/profile.php?id=100092971424652&mibextid=LQQJ4d"
                  target="_blank"
                  className="facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                {/* <a href="#" className="twitter" target="_blank"><i className="fab fa-twitter"></i></a> */}
                <a
                  href="http://linkedin.com/company/zaimah-technologies/"
                  target="_blank"
                  className="linkedin"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                {/* <a href="https://api.whatsapp.com/send?phone=+8801714-131050" className="whatsapp" target="_blank"><i className="fab fa-whatsapp"></i></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=+66840673605&text=test"
                  className="whatsapp"
                  target="_blank"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>

                <a
                  href="https://www.instagram.com/zaimahtech/"
                  className="instagram"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                {/* <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
