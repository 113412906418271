import React from "react";

const GoogleMap = () => {
  return (
    <>
      <div className="pt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15502.351291233215!2d100.52569486725028!3d13.743386458101044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29edb196be1a1%3A0x735a68d2ec48789c!2sMercury%20Tower!5e0!3m2!1sen!2sbd!4v1723894414370!5m2!1sen!2sbd"
          style={{ width: "100%", height: "400px", frameborder: "0" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};

export default GoogleMap;
